/*
 * NewsletterAnaylticsTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file NewsletterAnaylticsTable.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { INewsletterStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import { fetchNewsletterAnalytics } from '../../../Store/Newsletter';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { Column } from 'primereact/column';
import { translate } from '../../../Utils/Translate';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { INewsletterAnalytics } from '@abstract/abstractwebcommon-shared/interfaces/license/Newsletter';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

/**
 * @interface INewsletterAnaylticsTableProperties
 */
interface INewsletterAnaylticsTableProperties {
  newsletterID: string /**< newsletter ID. */;
}

/**
 * @interface INewsletterAnaylticsPayload
 */
interface INewsletterAnaylticsPayload extends ITablePayload {
  newsletterID: number /**< newsletter ID. */;
}

/**
 * NewsletterAnaylticsTable component.
 */
const NewsletterAnaylticsTable = (): JSX.Element => {
  const parameters: INewsletterAnaylticsTableProperties =
    useParams<INewsletterAnaylticsTableProperties>();
  const dispatch: Dispatch<any> = useDispatch();
  const newsletterState: INewsletterStateSelector = useSelector(
    (state: IStateSelectors) => state.newsletter
  ); /**< Newsletter state */

  const [isEmailList, setEmailList] = useState<boolean | null>(null);

  const [payload, setPayload] = useState<INewsletterAnaylticsPayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'sentDate',
      sortOrder: 1
    },
    searchTerm: '',
    newsletterID: parseInt(parameters.newsletterID)
  }); /**< Default Payload */
  const location = useLocation<any>();
  const title: string = location.state; /**< Newsletter title. */

  /// Handle sort update event
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: INewsletterAnaylticsPayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    dispatch(fetchNewsletterAnalytics(updatedPayload));
  };

  /// Handle page update event
  const handlePageUpdate = (event: IPageEvent): void => {
    const first: number = event.first;
    const rows: number = event.rows;
    const updatedPayload: INewsletterAnaylticsPayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      skip: first,
      limit: rows
    });
    setPayload(updatedPayload);
    dispatch(fetchNewsletterAnalytics(updatedPayload));
  };

  /// Handle filter update event
  const handleFilterUpdate = (event: string): void => {
    const updatedPayload: INewsletterAnaylticsPayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setPayload(updatedPayload);
    dispatch(fetchNewsletterAnalytics(updatedPayload));
  };

  useEffect(() => {
    dispatch(fetchNewsletterAnalytics(payload));
    /// add a newsletter title to the header
    const pageHeader: HTMLCollectionOf<Element> =
      document.getElementsByClassName('page-header-container');
    if (pageHeader[0]) {
      pageHeader[0].innerHTML = title + ' Analytics';
    }
    localStorage.setItem(
      'analytics_newsletterID',
      parameters.newsletterID
    ); /**< Add newsletterID to localstorage used to highlight sidebar menu */
    window.dispatchEvent(new Event('storage')); // Dispatch an event when changes to local storage.
  }, []);

  useEffect(() => {
    if (newsletterState.newsletterAnalytics && newsletterState.newsletterAnalytics.records) {
      // If 'newsletterAnalytics.records' does have a record where .username' is null, it means that the 'newsletterAnalytics.records' is from a newsletter of an email list.
      setEmailList(
        newsletterState.newsletterAnalytics.records.some(
          (newsletterAnalytic: INewsletterAnalytics) => newsletterAnalytic.username == null
        )
      );
    }
  }, [newsletterState.newsletterAnalytics]);

  const header: JSX.Element = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer"></div>
      <SearchBar onSearchTermChanged={(data: string) => handleFilterUpdate(data)} />
    </div>
  );

  return (
    <>
      <Row>
        <Col>
          <BaseDatatable
            value={
              newsletterState.newsletterAnalytics && newsletterState.newsletterAnalytics.records
            }
            totalRecords={
              newsletterState.newsletterAnalytics &&
              newsletterState.newsletterAnalytics.totalRecords
            }
            isLoading={!newsletterState.newsletterAnalytics}
            header={header}
            parentClass="newsletterAnalyticsDataTable" /**< ClassName for div Component.*/
            first={payload.skip}
            emptyMessage={translate('I18N.admin.page.newsletterAnalytics.table.empty_msg')}
            rows={payload.limit}
            onPage={handlePageUpdate}
            sortField={payload.sort.sortField}
            onSort={handleSortUpdate}
            sortOrder={payload.sort.sortOrder}
            onFilter={handleFilterUpdate}
            bodyStyle={'text-center'}
            responsive>
            <Column selectionMode="multiple" className="d-none" />

            {!isEmailList && (
              <Column
                sortable
                field="username"
                body={(rowData: INewsletterAnalytics) => (
                  <DatatableColumn
                    title={translate('I18N.admin.page.newsletterAnalytics.table.username')}
                    data={rowData.username}
                  />
                )}
                header={translate('I18N.admin.page.newsletterAnalytics.table.username')}
              />
            )}

            <Column
              sortable
              field="email"
              body={(rowData: INewsletterAnalytics) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletterAnalytics.table.email')}
                  data={rowData.email}
                />
              )}
              header={translate('I18N.admin.page.newsletterAnalytics.table.email')}
            />
            <Column
              field="sendStatus"
              sortable
              body={(rowData: INewsletterAnalytics) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletterAnalytics.table.sendStatus')}
                  data={rowData.sendStatus}
                />
              )}
              header={translate('I18N.admin.page.newsletterAnalytics.table.sendStatus')}
              className="createdDateCol"
            />
            <Column
              sortable
              field="sentDate"
              body={(rowData: INewsletterAnalytics) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletterAnalytics.table.sentDate')}
                  data={rowData.sentDate ? formatDate(rowData.sentDate, { isTime: true }) : ''}
                />
              )}
              header={translate('I18N.admin.page.newsletterAnalytics.table.sentDate')}
              className="col-width-12"
            />
            <Column
              field="isOpen"
              header={translate('I18N.admin.page.newsletterAnalytics.table.isOpen')}
              sortable
              className="d-table-cell d-sm-none d-md-table-cell custom-header-min-width-allowed"
              body={(rowData: INewsletterAnalytics) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletterAnalytics.table.isOpen')}
                  data={<ShowCheckOrUncheckIcon value={rowData.isOpen} />}
                />
              )}
            />
            <Column
              sortable
              field="openedDate"
              body={(rowData: INewsletterAnalytics) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletterAnalytics.table.openedDate')}
                  data={formatDate(rowData.openedDate)}
                />
              )}
              header={translate('I18N.admin.page.newsletterAnalytics.table.openedDate')}
              className="d-table-cell d-sm-none d-xl-table-cell createdDateCol"
            />
          </BaseDatatable>
        </Col>
      </Row>
    </>
  );
};

export default NewsletterAnaylticsTable;
